import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { PRODUCTS } from "constants/products";
import { StaticImgProps } from "components/ProductStaticImage";
import aggSigAnimation from "assets/aggSigAnimation.gif";

const imgClass = "h-44 w-80 sm:h-auto sm:w-full sm:max-w-xl";
const objectFit = "contain";

const KeelungIntroStaticImg: FC<StaticImgProps> = ({ alt }) => {
  return (
    <StaticImage
      src="../../../assets/home_products_keelung.png"
      alt={alt}
      objectFit={objectFit}
      className={imgClass}
    />
  );
};

const PQScaleIntroStaticImg: FC<StaticImgProps> = ({ alt }) => {
  return <img src={aggSigAnimation} alt={alt} />;
};

const KentingIntroStaticImg: FC<StaticImgProps> = ({ alt }) => {
  return (
    <StaticImage
      src="../../../assets/products_product_kenting.png"
      alt={alt}
      objectFit={objectFit}
      className={imgClass}
    />
  );
};

const QByteIntroStaticImg: FC<StaticImgProps> = ({ alt }) => {
  return (
    <StaticImage
      src="../../../assets/products_product_qByte.png"
      alt={alt}
      objectFit={objectFit}
      className={imgClass}
    />
  );
};

export const PRODUCT_STATIC_IMG_COMPONENT = {
  [PRODUCTS.KEELUNG]: KeelungIntroStaticImg,
  [PRODUCTS.KENTING]: KentingIntroStaticImg,
  [PRODUCTS.PQSCALE]: PQScaleIntroStaticImg,
  [PRODUCTS.QBYTE]: QByteIntroStaticImg,
  [PRODUCTS.BTQNETWORK]: null,
  [PRODUCTS.NIUM]: null,
};
