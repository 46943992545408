import * as React from "react";
import { PageProps } from "gatsby";
import { useIntl } from "react-intl";

import withI18n from "i18n/withI18n";
import { Layout } from "components/Layout";
import Overview from "views/products/overview";
import SEO from "components/seo";
import BgImg from "./background";

const ProductsPage: React.FC<PageProps> = ({ location }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({ id: "PRODUCTS_PAGE_TITLE" })}
        pathname={location.pathname}
        titlePrefix
        locale
      />
      {/* the BgImg already taken care of the background color */}
      <Layout background={{ component: <BgImg className="h-full w-full" /> }}>
        <Overview />
      </Layout>
    </>
  );
};

export default withI18n(ProductsPage);
