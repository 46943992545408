import React, { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import { Title } from "components/Title";
import {
  PRODUCTS,
  PRODUCTS_ICON,
  PRODUCTS_LIST,
  PRODUCTS_INTRODUCTION_IMG,
  PRODUCTS_WEBSITE,
  PRODUCT_ANCHOR,
  PRODUCTS_NAME,
  PRODUCTS_SUBTITLE,
  PRODUCTS_DESCRIPTION,
} from "constants/products";

import { GhostButton } from "components/Button";
import { CustomLink } from "components/CustomLink";
import ProductStaticImage from "components/ProductStaticImage";
import { PRODUCT_STATIC_IMG_COMPONENT } from "./StaticMedia";

import { SLUG } from "constants/slug";

const PRODUCTS_DETAIL_LIST = PRODUCTS_LIST.map((product) => ({
  product,
  title: PRODUCTS_NAME[product],
  subTitle:
    product === PRODUCTS.KEELUNG || product === PRODUCTS.PQSCALE
      ? PRODUCTS_SUBTITLE[product]
      : undefined,
  description: PRODUCTS_DESCRIPTION[product],
  imgUrl: PRODUCTS_INTRODUCTION_IMG[product],
  iconImgUrl: PRODUCTS_ICON[product],
  link: PRODUCTS_WEBSITE[product],
  id: product,
}));

interface ProductCardProps {
  product: PRODUCTS;
  title: JSX.Element;
  subTitle: JSX.Element | undefined;
  description: JSX.Element | undefined;
  iconImgUrl: string;
  link?: string;
  id: string;
}

const ProductCard: FC<ProductCardProps> = ({
  product,
  title,
  subTitle,
  description,
  iconImgUrl,
  link,
  id,
}) => {
  return (
    <div
      id={id}
      className="
        grid grid-cols-1 items-center rounded bg-btq-card-bg-dark px-6 pt-6 pb-5 
        xl:grid-cols-2 xl:gap-x-2 xl:rounded-xl xl:px-12 xl:py-16"
    >
      {/* description box */}
      <div className="flex flex-col">
        {/* product title */}
        <Title subTitle={subTitle} as="h3" titleClass="xl:text-4xl">
          <div className="flex items-center xl:flex-col xl:items-start">
            {/* product icon */}
            <div className="mr-5 mb-1 aspect-square h-10 xl:mr-0 xl:mb-8 xl:h-16 ">
              <img src={iconImgUrl} alt="" className="h-full w-full object-contain" />
            </div>
            {title}
          </div>
        </Title>
        {/* product paragraph */}
        <p className="mb-3 mt-5 text-btq-sub-text-normal sm:mb-6 sm:text-xl xl:min-h-[6.25rem] xl:max-w-xl">
          {description}
        </p>
      </div>
      {/* read more button */}
      <div className="order-3 justify-self-center xl:col-start-1 xl:col-end-2 xl:justify-self-auto">
        {link && (
          <CustomLink href={link}>
            <GhostButton className="xl:px-10 xl:py-5">
              <FormattedMessage id="HOME_PRODUCT_LEARN_MORE" />
            </GhostButton>
          </CustomLink>
        )}
      </div>
      {/* image box */}
      <div className="order-2 mb-5 h-44 w-80 justify-self-center sm:h-auto sm:w-full sm:max-w-xl xl:col-start-2 xl:col-end-3 xl:row-start-1 xl:row-end-3 xl:mb-0">
        <ProductStaticImage
          product={product}
          alt=""
          staticImgComponentObj={PRODUCT_STATIC_IMG_COMPONENT}
        />
      </div>
    </div>
  );
};

const ProductList = () => (
  <div className="mt-6 xl:mt-32">
    <div className="flex flex-col space-y-4 pb-12">
      {PRODUCTS_DETAIL_LIST.map((product) => (
        <ProductCard {...product} key={product.id} />
      ))}
    </div>
  </div>
);

const OVERVIEW_PRODUCT_LIST = PRODUCTS_LIST.map((product) => {
  return {
    name: PRODUCTS_NAME[product],
    iconImgUrl: PRODUCTS_ICON[product],
    link: `${SLUG.PRODUCTS}${PRODUCT_ANCHOR[product]}`,
    id: product,
  };
});

const OverviewList = () => (
  <div
    className={
      "flex w-screen items-start justify-start overflow-scroll scrollbar-hide md:w-full md:justify-center md:overflow-visible xl:space-x-9"
    }
  >
    {OVERVIEW_PRODUCT_LIST.map(({ name, iconImgUrl, link, id }) => (
      <div
        className="justify-startmd:min-w-0 flex w-40 min-w-[8rem] flex-col items-center"
        key={id}
      >
        <CustomLink className="h-12 w-12 sm:mb-4 sm:h-14 sm:w-14" href={link}>
          <img
            src={iconImgUrl}
            alt={`BTQ's products - ${name}`}
            className="h-full w-full object-contain"
          />
        </CustomLink>
        <span className="w-full text-center text-base leading-5 xl:text-2xl ">{name}</span>
      </div>
    ))}
  </div>
);

const HeroSlogan = () => {
  const style = (chunks: Array<ReactNode>) => <span className="text-primary">{chunks}</span>;
  const values = { style };
  return <FormattedMessage id="PRODUCTS_HERO_SLOGAN" values={values} />;
};

const Hero = () => (
  <div className="flex flex-col items-center justify-center bg-transparent pt-32 pb-6 xl:pt-[15rem]">
    <Title className="items-center">
      <HeroSlogan />
    </Title>

    <p className="mt-4 mb-6 text-center leading-loose sm:mt-5 sm:mb-10 sm:text-2xl xl:mt-7 xl:mb-12">
      {/* temperately have no description */}
    </p>

    <OverviewList />
    <ProductList />
  </div>
);

const Overview = () => {
  return (
    <Section>
      <Hero />
    </Section>
  );
};

export default Overview;
